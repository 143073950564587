import React from 'react'
import { BrowserRouter, Link, Route, Routes } from "react-router-dom"

function Browsernav() {
    return (
        <div className="row-30 ">
            <div className="navsm text-xs-right">
                <div className="ui item top-drop-in-css">
                    <Link to="/real">Trading</Link>
                </div>
                <div className="ui item top-drop-in-css">
                    <a href="javascript:;">Account</a>
                    <div className="menu">
                        <div className="item"><Link to="/deposit"> Funding Account </Link></div>
                        <div className="item"><Link to="/withdrawal"> Withdrawals</Link></div>
                    </div>
                </div>
                <div className="ui item top-drop-in-css">
                    <a href="javascript:;">Robotic Trading</a>
                    <div className="menu">
                        <div className="item"><Link to="/roboticsoftware"> Robotic Software</Link></div>

                    </div>
                </div>
                <div className="ui item top-drop-in-css">
                    <a href="javascript:;">Statements</a>
                    <div className="menu">
                        <div className="item"><Link to="/transactionhistory"> Trading History</Link></div>
                        <div className="item"><Link to="/demohistory">Demo Trading History</Link></div>
                        <div className="item"><Link to="/deposithistory">Deposit history</Link></div>
                        <div className="item"><Link to="/withdrawalhistory"> Withdrawal History</Link></div>
                    </div>
                </div>
                <div className="ui item top-drop-in-css">
                    <a href="javascript:;">Settings</a>
                    <div className="menu">
                        <div className="item"><Link to="/profile"> Personal Data</Link></div>
                        <div className="item"><Link to="/security"> Security Settings</Link></div>
                    </div>
                </div>
                <div className="ui item top-drop-in-css">
                    <a href="/contact.html" target="_blank">Support</a>
                </div>
            </div>
        </div>
    )
}

export default Browsernav