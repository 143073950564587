import React from 'react'
import { Helmet } from "react-helmet";
import TradingViewWidget, { Themes } from 'react-tradingview-widget';

function Mobilereal() {
    return (
        <div>    <Helmet>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content="Luna FX was designed with all categories of traders in mind. With an easy to explore and use interface, we built integrity, innovation and security into a product that is quickly becoming the favorite among traders in over 35 countries." />
            <meta name="author" content="Luna FX" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="theme-color" content="#0134d4" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="black" />
            {/* Title*/}
            <title> Trade Earnings | Luna FX </title>
            {/* Fonts*/}
            <link rel="preconnect" href="https://fonts.gstatic.com/" />
            <link href="https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" />
            {/* Favicon*/}
            <link rel="icon" type="image/png" href="https://luna-fx.com/front-assets/img/favicon.png" />
            {/* CSS Libraries*/}
            <style dangerouslySetInnerHTML={{ __html: "[wire\\:loading], [wire\\:loading\\.delay], [wire\\:loading\\.inline-block], [wire\\:loading\\.inline], [wire\\:loading\\.block], [wire\\:loading\\.flex], [wire\\:loading\\.table], [wire\\:loading\\.grid], [wire\\:loading\\.inline-flex] {display: none;}[wire\\:loading\\.delay\\.shortest], [wire\\:loading\\.delay\\.shorter], [wire\\:loading\\.delay\\.short], [wire\\:loading\\.delay\\.long], [wire\\:loading\\.delay\\.longer], [wire\\:loading\\.delay\\.longest] {display:none;}[wire\\:offline] {display: none;}[wire\\:dirty]:not(textarea):not(input):not(select) {display: none;}input:-webkit-autofill, select:-webkit-autofill, textarea:-webkit-autofill {animation-duration: 50000s;animation-name: livewireautofill;}@keyframes livewireautofill { from {} }" }} />
            <link rel="stylesheet" href="/assets/mobile/css/bootstrap.min.css" />
            {/* <link rel="stylesheet" href=""> */}
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
            <link rel="stylesheet" href="/assets/mobile/css/magnific-popup.css" />
            <link rel="stylesheet" href="/assets/mobile/css/ion.rangeSlider.min.css" />
            {/* Core Stylesheet*/}
            <link rel="stylesheet" href="/assets/mobile/css/style.css" />
            <link href="/assets/mobile/css/sweetalert.css" rel="stylesheet" type="text/css" />
            <script src="/assets/mobile/js/bootstrap.bundle.min.js"></script>
            <script src="/assets/mobile/js/jquery.min.js"></script>
            <script src="/assets/mobile/js/internet-status.js"></script>
            <script src="/assets/mobile/js/waypoints.min.js"></script>
            <script src="/assets/mobile/js/jquery.easing.min.js"></script>
            <script src="/assets/mobile/js/wow.min.js"></script>
            <script src="/assets/mobile/js/isotope.pkgd.min.js"></script>
            <script src="/assets/mobile/js/jquery.magnific-popup.min.js"></script>
            <script src="/assets/mobile/js/ion.rangeSlider.min.js"></script>
            <script src="/assets/mobile/js/active.js"></script>

            <script src="/assets/mobile/js/jquery.js"></script>
            <script src="/assets/mobile/js/fastclick.js"></script>
            <script src="https://cdn.jsdelivr.net/npm/sweetalert2@11"></script>
            <script src="/assets/mobile/js/bootstrap-input-spinner.js"></script>
            <script src="/livewire/livewire.js?id=940557fc56b15ccb9a2d" data-turbo-eval="false"
                data-turbolinks-eval="false"></script>
        </Helmet>
            {/* Preloader*/}
            <div className="preloader d-flex align-items-center justify-content-center" id="preloader">
                <div className="spinner-grow text-primary" role="status">
                    <div className="sr-only">Loading...</div>
                </div>
            </div>
            {/* Internet Connection Status*/}
            <div className="internet-connection-status" id="internetStatus" />
            {/* Header Area*/}
            <div className="header-area" id="headerArea">
                <div className="container">
                    {/* Header Content*/}
                    <div className="header-content header-style-five position-relative d-flex align-items-center justify-content-between">
                        {/* Logo Wrapper*/}
                        <div className="logo-wrapper"><a href><img src="https://luna-fx.com/assets/mobile/img/logo.png" alt="" /></a></div>
                        {/* Navbar Toggler*/}
                        <div className="navbar--toggler" id="affanNavbarToggler"><span className="d-block" /><span className="d-block" /><span className="d-block" /></div>
                    </div>
                    {/* # Header Five Layout End*/}
                </div>
            </div>
            {/* Sidenav Black Overlay*/}
            <div className="sidenav-black-overlay" />
            {/* Side Nav Wrapper*/}
            <div className="sidenav-wrapper" id="sidenavWrapper">
                {/* Go Back Button*/}
                <div className="go-back-btn" id="goBack">
                    <svg className="bi bi-x" width={24} height={24} viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" />
                        <path fillRule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
                    </svg>
                </div>
                {/* Sidenav Profile*/}
                <div className="sidenav-profile">
                    <div className="sidenav-style1" />
                    {/* User Thumbnail*/}
                    {/* User Info*/}
                    <div className="user-info">
                        <h6 className="user-name mb-0" style={{ color: 'white' }}> Osita Stephen</h6><br />
                        <h6 className="user-name mb-0" style={{ color: 'white' }}>Bal: $0.00</h6>
                        <h6 className="user-name mb-0" style={{ color: 'gold' }}>Btc: 0.0000</h6>
                    </div>
                </div>
                {/* Sidenav Nav*/}
                <ul className="sidenav-nav ps-0">
                    <li className="affan-dropdown-menu"><a href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-graph-up" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M0 0h1v15h15v1H0V0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5z" />
                        </svg>Trading</a>
                        <ul>
                            <li><a href="https://luna-fx.com/mobile/dashboard">Live Trading</a></li>
                            <li><a href="https://luna-fx.com/mobile/trade-history">Trade History</a></li>
                            <li><a href="https://luna-fx.com/mobile/demo/dashboard" onclick="event.preventDefault(); document.getElementById('switch-demo').submit();">
                                Switch to demo</a></li>
                        </ul>
                        <form action="https://luna-fx.com/switch-live" id="switch-live" method="POST" style={{ display: 'none' }}> <input type="hidden" name="_token" defaultValue="liYTble4TuY6xHW1fklYxHwcBln8LCTxGIeKvnV5" /> </form>
                        <form action="https://luna-fx.com/switch-demo" id="switch-demo" method="POST" style={{ display: 'none' }}> <input type="hidden" name="_token" defaultValue="liYTble4TuY6xHW1fklYxHwcBln8LCTxGIeKvnV5" /> </form>
                    </li>
                    <li className="affan-dropdown-menu"><a href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                        </svg>Finance</a>
                        <ul>
                            <li><a href="https://luna-fx.com/mobile/deposit">Deposit</a></li>
                            <li><a href="https://luna-fx.com/mobile/withdraw">Withdrawal</a></li>
                            <li><a href="https://luna-fx.com/mobile/deposit-log">Deposit History</a></li>
                            <li><a href="https://luna-fx.com/mobile/withdrawal-log">Withdrawal History</a></li>
                            <li><a href="https://luna-fx.com/mobile/earnings">Profits</a></li>
                        </ul>
                    </li>
                    <li className="affan-dropdown-menu"><a href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>Profile</a>
                        <ul>
                            <li><a href="https://luna-fx.com/mobile/edit-profile">Account Profile</a></li>
                            <li><a href="https://luna-fx.com/mobile/verify">KYC</a></li>
                        </ul>
                    </li>
                    <li><a href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-chat-left-dots" viewBox="0 0 16 16">
                            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                            <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>Messages (0)</a></li>
                    <li><a href="https://luna-fx.com/mobile/security">
                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-shield-fill-check" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647z" />
                        </svg>Security</a></li>
                    <li><a href="https://luna-fx.com/logout" onclick="event.preventDefault();
            document.getElementById('logout-form').submit();">
                        <svg width={20} height={20} viewBox="0 0 16 16" className="bi bi-box-arrow-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                            <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                        </svg>Logout</a>
                        <form id="logout-form" action="https://luna-fx.com/logout" method="POST" style={{ display: 'none' }}>
                            <input type="hidden" name="_token" defaultValue="liYTble4TuY6xHW1fklYxHwcBln8LCTxGIeKvnV5" />      </form>
                    </li>
                </ul>
                {/* Social Info*/}
                {/* Copyright Info*/}
                <div className="copyright-info">
                    <p> © 2022 <a href="https://luna-fx.com">Luna FX</a>. All Rights Reserved.</p>
                </div>
            </div>
            <div className="page-content-wrapper" >
                {/* <div className="owl-carousel-one owl-carousel">
                 
                </div> */}
                <div style={{ height: '80vh  !important' }}>
                    <TradingViewWidget
                        symbol={"EURUSD"}
                        theme={Themes.LIGHT}
                        locale="en"
                        autosize

                    />
                </div>
                <div>
                    <div className="affan-features-wrap py-3">
                        <div className="container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="col-form-label">Category</label>
                                                <select name="category" id="category" className="form-control select2 w-p100">
                                                    <option value={1}>CryptoCurrency</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="col-form-label">Symbol</label>
                                                <select className="form-control">
                                                    <option value="BCH/BTC">BCH/BTC</option>
                                                    <option value="BCH/EUR">BCH/EUR</option>
                                                    <option value="BCH/GBP">BCH/GBP</option>
                                                    <option value="BTC/EOS">BTC/EOS</option>
                                                    <option value="BTC/EUR">BTC/EUR</option>
                                                    <option value="BTC/GBP">BTC/GBP</option>
                                                    <option value="BTC/USDT">BTC/USDT</option>
                                                    <option value="BTC/USDC">BTC/USDC</option>
                                                    <option value="EOS/BTC">EOS/BTC</option>
                                                    <option value="EOS/EUR">EOS/EUR</option>
                                                    <option value="EOS/USD">EOS/USD</option>
                                                    <option value="ETC/BTC">ETC/BTC</option>
                                                    <option value="ETC/EUR">ETC/EUR</option>
                                                    <option value="ETC/GBP">ETC/GBP</option>
                                                    <option value="ETC/USD">ETC/USD</option>
                                                    <option value="ETH/BTC">ETH/BTC</option>
                                                    <option value="ETH/EUR">ETH/EUR</option>
                                                    <option value="ETH/GBP">ETH/GBP</option>
                                                    <option value="ETH/USDC">ETH/USDC</option>
                                                    <option value="LTC/BTC">LTC/BTC</option>
                                                    <option value="LTC/EUR">LTC/EUR</option>
                                                    <option value="LTC/GBP">LTC/GBP</option>
                                                    <option value="LTC/USDC">LTC/USDC</option>
                                                    <option value="MKR/BTC">MKR/BTC</option>
                                                    <option value="REP/BTC">REP/BTC</option>
                                                    <option value="REP/USD">REP/USD</option>
                                                    <option value="SOL/USDT">SOL/USDT</option>
                                                    <option value="TRX/EUR">TRX/EUR</option>
                                                    <option value="XLM/BTC">XLM/BTC</option>
                                                    <option value="XLM/EUR">XLM/EUR</option>
                                                    <option value="XLM/USDT">XLM/USDT</option>
                                                    <option value="XRP/BTC">XRP/BTC</option>
                                                    <option value="XRP/ETH">XRP/ETH</option>
                                                    <option value="XRP/USDT">XRP/USDT</option>
                                                    <option value="XRP/BNB">XRP/BNB</option>
                                                    <option value="ZEC/BTC">ZEC/BTC</option>
                                                    <option value="MATIC/EUR">MATIC/EUR</option>
                                                    <option value="MATIC/AUD">MATIC/AUD</option>
                                                    <option value="ADA/AUD">ADA/AUD</option>
                                                    <option value="SCRT/ETH">SCRT/ETH</option>
                                                    <option value="VET/EUR">VET/EUR</option>
                                                    <option value="ETH/USDT">ETH/USDT</option>
                                                    <option value="WAVES/EUR">WAVES/EUR</option>
                                                    <option value="ETH/UST">ETH/UST</option>
                                                    <option value="DOT/RUB">DOT/RUB</option>
                                                    <option value="BTC/RUB">BTC/RUB</option>
                                                    <option value="TRX/BTC">TRX/BTC</option>
                                                    <option value="EUR/KZT">EUR/KZT</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="col-form-label">Amount</label>
                                                <input type="number" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="col-form-label">Strike Rate</label><br />
                                                <select className="form-control custom-select" >
                                                    <option value>Select Option</option>
                                                    <option value={14}>
                                                        2% &amp; 2%
                                                    </option>
                                                    <option value={15}>
                                                        1% &amp; 3%
                                                    </option>
                                                    <option value={5}>
                                                        2% &amp; 5%
                                                    </option>
                                                    <option value={7}>
                                                        2% &amp; 3%
                                                    </option>
                                                    <option value={9}>
                                                        5% &amp; 5%
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="col-form-label" htmlFor="interval">Interval</label>
                                                <select className="form-control" >
                                                    <option value={60}>1 min</option>
                                                    <option value={180}>3 mins</option>
                                                    <option value={300}>5 mins</option>
                                                    <option value={900}>15 mins</option>
                                                    <option value={1800}>30 mins</option>
                                                    <option value={3600}>1 hr</option>
                                                    <option value={7200}>2 hrs</option>
                                                    <option value={86400}>1 day</option>
                                                    <option value={1296000}>15 days</option>
                                                    <option value={2592000}>30 days</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group text-center mt-20">
                                                <label className="text-success" id="buyamount">$0.00</label>
                                                <br />
                                                <button type="button" className="btn btn-success mb-1" >
                                                    BUY
                                                </button>
                                                <button type="button" className="btn btn-danger" >
                                                    SELL
                                                </button>
                                                <br />
                                                <label className="text-danger" id="sellamount">$0.00</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="element-heading">
                            <h6>Recent Trades</h6>
                        </div>
                    </div>
                    <div className="container">
                        <div className="card">
                            <div className="card-body">
                                <div className="box-body no-padding">
                                    <div className="table-responsive">
                                        <table className="table table-adaptive" style={{ textAlign: 'center' }}>
                                            <thead>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <th>Trade Time</th>
                                                    <th>Symbol</th>
                                                    <th>Amount</th>
                                                    <th>Strike Rate</th>
                                                    <th>End Price</th>
                                                    <th>End Time</th>
                                                    <th>Status</th>
                                                    <th>Trading Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={9}>
                                                        <div className="alert alert-danger text-center text-bold"> No Trade in History Yet! </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Livewire Component wire-end:X8KZ3FMPqMGJLJVemzjQ */}
                <div className="my-2 py-2" />
            </div>
            {/* Footer Nav*/}
            <div className="footer-nav-area" id="footerNav">
                <div className="container px-0">
                    {/* Footer Content*/}
                    <div className="footer-nav position-relative">
                        <ul className="h-100 d-flex align-items-center justify-content-between ps-0">
                            <li className="active"><a href="https://luna-fx.com/mobile/dashboard"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-graph-up" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M0 0h1v15h15v1H0V0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5z" />
                            </svg><span>Trading</span></a></li>
                            <li><a href="https://luna-fx.com/mobile/trade-history">
                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-cash" viewBox="0 0 16 16">
                                    <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                                    <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z" />
                                </svg><span>Trade History</span></a></li>
                            <li><a href="https://luna-fx.com/mobile/deposit">
                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-wallet" viewBox="0 0 16 16">
                                    <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                                </svg><span>Deposit</span></a></li>
                            <li><a href="https://luna-fx.com/mobile/withdraw">
                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-arrow-bar-down" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z" />
                                </svg><span>Withdraw</span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* All JavaScript Files*/}
            {/*  */}
            <div>
            </div>
            <div>
                <style dangerouslySetInnerHTML={{ __html: "div.fixed {position: fixed; top: 0; right: 0; }" }} />
                {/* google translate */}
                <style dangerouslySetInnerHTML={{ __html: ".goog-logo-link { display:none !important; } .goog-te-gadget { color: transparent !important; } .goog-te-gadget .goog-te-combo { color: blue !important; }.goog-te-banner-frame.skiptranslate { display: none !important; }  body { top: 0px !important; }" }} />
                {/* // google translate */}
                <div id="google_translate_element" className="fixed" />
            </div>
        </div>
    )
}

export default Mobilereal