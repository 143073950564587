import React, { useReducer } from "react";

export const GlobalContext = React.createContext();

const initialState = {
    userdetails: {},
    productdetails: {},
    loggedin: false,
    paymentfor: "",
    productinfo: {},
    singleuser: "",
    details: false,
    draweropen: false,
    productedit: false,
    tradingpair: "ETHBTC",
    depositinfo: {},
    openorders: [],
    Demoopenorders: [],
    adminstaus: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case "setadminstaus": {
            return {
                ...state,
                adminstaus: action.snippet
            };
        }
        case "setDemoopenorders": {
            return {
                ...state,
                Demoopenorders: action.snippet
            };
        }
        case "setopenorders": {
            return {
                ...state,
                openorders: action.snippet
            };
        }
        case "setdepositinfo": {
            return {
                ...state,
                depositinfo: action.snippet
            };
        }
        case "setproductdetails": {
            return {
                ...state,
                productdetails: action.snippet
            };
        }

        case "setproductedit": {
            return {
                ...state,
                productedit: action.snippet
            };
        }

        case "settradingpair": {
            return {
                ...state,
                tradingpair: action.snippet
            };
        }

        case "setdraweropen": {
            return {
                ...state,
                draweropen: action.snippet
            };
        }
        case "setdetails": {
            return {
                ...state,
                details: action.snippet
            };
        }

        case "setproductinfo": {
            return {
                ...state,
                productinfo: action.snippet
            };
        }
        case "setuserdetails": {
            return {
                ...state,
                userdetails: action.snippet
            };
        }
        case "setpaymentfor": {
            return {
                ...state,
                paymentfor: action.snippet
            };
        }
        case "setsingleuser": {
            return {
                ...state,
                singleuser: action.snippet
            };
        }
        case "setloggedin": {
            return {
                ...state,
                loggedin: action.snippet
            }
        }
        default:
            return state;
    }
};

export const GlobalState = props => {
    const globalState = useReducer(reducer, initialState);
    return (
        <GlobalContext.Provider value={globalState}>
            {props.children}
        </GlobalContext.Provider>
    );
};