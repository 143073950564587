import React, { useContext, useState, useEffect, useRef } from "react";

function Home() {
    useEffect(() => {
        // navigate("/example");
        global.window && (global.window.location.href = '/home.html')
    }, []);
    return (
        <div></div>
    )
}

export default Home